import * as Sentry from '@sentry/react';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreatePerson, CreateUser, PeopleService, UserService } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledNoDataReducer, asyncPendingReducer, handleErrors, useAppSelector } from '../hooks';
import { UserState } from './types';

export type CreateUserAndPerson = CreateUser & CreatePerson;

const defaultData: UserState = {
  loginEmail: '',
  user: null,
  userLoadCalled: false,
};

const initialState: AsyncState<UserState> = {
  data: defaultData,
  processing: false,
  error: false,
  errors: [],
};

export const loadUser = createAsyncThunk('user/load', () => handleErrors(() => UserService.getUser()));

export const loginUser = createAsyncThunk(
  'user/login',
  async ({ email, password }: { email: string; password: string }, thunkApi) => {
    return handleErrors(async () => {
      const result = await UserService.login({ email, password });
      if (result.success) {
        await thunkApi.dispatch(loadUser());
      }
      return result;
    });
  }
);

export const logoutUser = createAsyncThunk('user/logout', () => handleErrors(() => UserService.logout()));

export const createUser = createAsyncThunk('user/create', async (data: CreateUserAndPerson, thunkApi) => {
  return handleErrors(async () => {
    const result = await UserService.createUser(data);
    if (result.success) {
      await thunkApi.dispatch(loadUser());
    }

    return result;
  });
});

export const userExists = createAsyncThunk('user/exists', (email: string) =>
  handleErrors(() => UserService.userExists(email))
);

export const resetPassword = createAsyncThunk('user/reset', (email: string) =>
  handleErrors(() => UserService.resetPassword({ email }))
);

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateLogin: (state, action: PayloadAction<Partial<UserState>>) => {
      state.data = state.data || defaultData;

      if (action.payload.loginEmail) {
        state.data.loginEmail = action.payload.loginEmail;
      }
      if (action.payload.user !== undefined) {
        state.data.user = action.payload.user;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, asyncPendingReducer);
    builder.addCase(loginUser.fulfilled, (state, action) => {
      asyncFulfilledNoDataReducer(state, action);
      if (!action.payload.data?.success) {
        state.error = true;
        state.errors = ['Login failed'];
      }
    });
    builder.addCase(logoutUser.pending, asyncPendingReducer);
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      asyncFulfilledNoDataReducer(state, action);
      if (!action.payload.data?.success) {
        state.data = defaultData;
        state.error = false;
        state.errors = [];
      }
    });
    builder.addCase(createUser.pending, asyncPendingReducer);
    builder.addCase(createUser.fulfilled, (state, action) => {
      asyncFulfilledNoDataReducer(state, action);
      if (!action.payload.data?.success) {
        state.error = true;
        state.errors = [action.payload.data?.error || 'Unknown Error'];
      }
    });
    builder.addCase(loadUser.pending, asyncPendingReducer);
    builder.addCase(loadUser.fulfilled, (state, action) => {
      state.data = state.data || defaultData;
      asyncFulfilledNoDataReducer(state, action);
      state.data.user = action.payload.data;
      if (state.data.user?.id) Sentry.setUser({ id: `${state.data.user.id}` });
      state.data.userLoadCalled = true;
    });
    builder.addCase(userExists.pending, asyncPendingReducer);
    builder.addCase(userExists.fulfilled, asyncFulfilledNoDataReducer);
  },
});

export const { updateLogin } = slice.actions;

export const useUserStateSelector = () => useAppSelector((state) => state.user);
export const useUserSelector = () => useAppSelector((state) => state.user.data?.user);
export const useLoginEmailSelctor = () => useAppSelector((state) => state.user.data?.loginEmail);

export const useUserStateErrorSelector = () => useAppSelector((state) => state.user.error);
export const useUserStateErrorsSelector = () => useAppSelector((state) => state.user.errors);

export default slice.reducer;
