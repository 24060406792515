import * as React from 'react';
import { Button, FieldMessage, Statuses } from '@teamsnap/teamsnap-ui';
import { useLocation } from 'react-router-dom';

// Local Imports
import { HouseholdPerson } from 'core/api';
import { useUserSelector } from 'state/user/userSlice';
import {
  useHouseholdsSelector,
  useGroupedHouseholdPeopleSelector,
  usePrimaryHouseholdIdSelector,
} from 'state/households/householdSlice';
import { ActionContainer } from '../../shared';
import { PhotoCard } from '../PhotoCard/PhotoCard';
import { ManageHouseholdMember } from '../ManageHouseholdMember/ManageHouseholdMember';
import { useFormSelector } from 'state/form/formSlice';
import { updateRegistration, useInCartRegistrationsSelector } from 'state/registration/registrationSlice';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { Header } from '../Header/Header';
import { CartDetails } from '../CartDetails/CartDetails';
import { CartButton } from 'components/shared/CartButton';

export interface HouseholdMembersProps {
  members: HouseholdPerson[] | null;
}

type PrevPathState = {
  state: {
    isConfirmOpen: boolean;
    member: HouseholdPerson;
  };
};

export const HouseholdMembers = () => {
  const [isAddOpen, setIsAddOpen] = React.useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [currentMember, setCurrentMember] = React.useState<HouseholdPerson | undefined>(undefined);
  const [selectedMember, setSelectedMember] = React.useState<HouseholdPerson | undefined>(undefined);

  const form = useFormSelector();
  const { organizationName } = form || {};

  const registrations = useInCartRegistrationsSelector();
  const primaryHouseholdId = usePrimaryHouseholdIdSelector();
  const households = useHouseholdsSelector();
  const groupedHouseholds = useGroupedHouseholdPeopleSelector();
  const user = useUserSelector();

  const currentForm = useFormSelector();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation() as PrevPathState;

  if (!isConfirmOpen && location && location.state && location.state['isConfirmOpen']) {
    setIsConfirmOpen(location.state['isConfirmOpen']);
    setCurrentMember(location.state['member']);
    setSelectedMember(location.state['member']);
  }

  React.useEffect(() => {
    dispatch(
      updateRegistration({
        currentMember: undefined,
        currentGroup: undefined,
      })
    );
  }, []);

  const handleOnParticipantAdd = () => {
    setIsEditOpen(false);
    setIsAddOpen(true);
  };

  const handleOnParticipantEdit = (member: HouseholdPerson) => {
    setCurrentMember(member);
    setIsAddOpen(false);
    setIsEditOpen(true);
    setIsConfirmOpen(false);
  };

  const handleOnParticipantSelect = (member: HouseholdPerson) => {
    setHasError(false);
    setSelectedMember(member);

    if (currentForm) {
      setIsAddOpen(false);
      setIsEditOpen(false);
      setIsConfirmOpen(true);
      setCurrentMember(member);
    }
  };

  const goNext = () => {
    if (selectedMember) {
      navigate(`/form/${currentForm?.id}/selectGroup`);
    } else {
      setHasError(true);
    }
  };

  const renderSubheading = (participant: HouseholdPerson) => {
    if (participant.isOrganizer) {
      return 'Household Organizer';
    }
    const {
      person: { age },
    } = participant;
    if (age === 0) {
      return 'Age <1';
    }
    if (age) {
      if (!age && age !== 0) {
        return '';
      }

      if (age >= 18) {
        return 'Adult';
      }

      return `Age ${age}`;
    }

    return '';
  };

  const participantCards = (householdPeople: HouseholdPerson[]) =>
    householdPeople.map((member, index) => (
      <PhotoCard
        key={index}
        label={`${member.person?.firstName} ${member.person?.lastName}`}
        subheading={renderSubheading(member)}
        displayIcon
        onClickIcon={() => handleOnParticipantEdit(member)}
        onClickWrapper={() => handleOnParticipantSelect(member)}
        isSelected={selectedMember?.id === member.id}
        subcontent={
          <CartDetails member={member} registrations={registrations} isSelected={selectedMember?.id === member.id} />
        }
      />
    ));

  return (
    <>
      {isAddOpen && (
        <ManageHouseholdMember
          primaryHouseholdId={primaryHouseholdId}
          isOpen={isAddOpen}
          households={households}
          setIsOpen={setIsAddOpen}
          type="add"
        />
      )}
      {isEditOpen && (
        <ManageHouseholdMember
          primaryHouseholdId={primaryHouseholdId}
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          type="edit"
          householdMember={currentMember}
        />
      )}
      {isConfirmOpen && (
        <ManageHouseholdMember
          primaryHouseholdId={primaryHouseholdId}
          isOpen={isConfirmOpen}
          setIsOpen={setIsConfirmOpen}
          type="confirm"
          householdMember={currentMember}
          onCancel={() => {
            setSelectedMember(undefined);

            // Reset prev state
            if (isConfirmOpen && location && location.state && location.state['isConfirmOpen']) {
              navigate(`/form/${form?.id}/selectParticipant`);
            }
          }}
        />
      )}

      <ActionContainer
        displayBackButton
        goBackAction={() => navigate(`/form/${currentForm?.id}`)}
        action={() => goNext()}
        actionLabel="Next"
        header={
          <Header
            title={organizationName || 'Select Participant'}
            navigation={
              <Button
                iconPosition="left"
                mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                icon="arrow-left"
                type="link"
                size="large"
                onClick={() => navigate(`/form/${currentForm?.id}`)}
              />
            }
            rightIcon={<CartButton />}
            profileName={`${user?.firstName} ${user?.lastName}`}
          />
        }
      >
        <>
          <div style={{ height: '56px' }}>
            <h3 className="sui-heading-md sui-font-normal">Who are you registering today?</h3>
            <p className="sui-heading-body">Please select one at a time.</p>
          </div>

          {hasError && (
            <FieldMessage mods="sui-caption" status={Statuses.ERROR}>
              Please select a household member.
            </FieldMessage>
          )}

          {households &&
            groupedHouseholds &&
            households.map((h) => (
              <div className="u-padTopLg" key={h.id}>
                <div className="sui-heading-md">{h.name} Household</div>
                <div className="u-padTopLg" data-testid={`household-${h.id}`}>
                  {groupedHouseholds[h.id] &&
                    groupedHouseholds[h.id].length > 0 &&
                    participantCards(groupedHouseholds[h.id])}
                </div>
              </div>
            ))}

          <div className="">
            <Button
              size="large"
              style={{ height: '80px' }}
              mods="u-sizeFull"
              icon="plus"
              onClick={handleOnParticipantAdd}
            >
              <span data-testid="add-household-member">Add a household member</span>
            </Button>
          </div>
        </>
      </ActionContainer>
    </>
  );
};
