import { Button, ScreenReader, Tooltip } from '@teamsnap/teamsnap-ui';

// Local Imports
import { ProfileImage } from '../ProfileImage/ProfileImage';
import './PhotoCard.scss';

export interface PhotoCardProps {
  label: string;
  displayIcon?: boolean;
  iconDisabled?: boolean;
  imageSrc?: string;
  imageAltText?: string;
  onClickIcon?: () => void;
  onClickWrapper?: () => void;
  onKeyDownWrapper?: () => void;
  rightIcon?: string;
  subcontent?: React.ReactNode;
  subheading?: string;
  isSelected?: boolean;
}

export const PhotoCard = ({
  label,
  displayIcon,
  iconDisabled,
  imageSrc,
  imageAltText,
  onClickIcon,
  onClickWrapper,
  onKeyDownWrapper,
  rightIcon,
  subcontent,
  subheading,
  isSelected,
}: PhotoCardProps) => {
  const handleIconClick = () => {
    if (onClickIcon) {
      onClickIcon();
    }
  };

  const handleWrapperClick = (e: React.BaseSyntheticEvent) => {
    if (e.target.closest('button') !== null) {
      if (onClickIcon) {
        onClickIcon();
        return;
      }
    }

    if (onClickWrapper) {
      onClickWrapper();
    }
  };

  const Card = () => (
    <div
      onClick={(e) => handleWrapperClick(e)}
      onKeyDown={onKeyDownWrapper}
      role="button"
      tabIndex={0}
      data-testid="photocard"
      className={`PhotoCard u-border u-borderColorDefault u-padMd u-spaceBottomMd u-borderRadiusMd ${
        isSelected ? 'u-borderColorPrimary u-bgPrimary1' : ''
      }`}
    >
      <div className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
        <ProfileImage name={label} altText={imageAltText ?? ''} src={imageSrc} />

        <div className="sui-ml-4 u-sizeFill">
          <Tooltip text={label} style={{ wordBreak: 'break-word' }}>
            <h3 className="sui-body u-textEllipsis">{label}</h3>
          </Tooltip>

          {subheading && <p className="sui-caption sui-text-gray-8">{subheading}</p>}
        </div>

        {displayIcon && (
          <div data-testid="photocard-icon" className={`photocard-icon-btn-${label.toLowerCase().replace(' ', '-')}`}>
            <Button
              onClick={handleIconClick}
              isDisabled={iconDisabled}
              icon={rightIcon ?? 'edit'}
              mods="sui-w-6 sui-h-6 photocard-icon-btn"
            >
              <ScreenReader>{rightIcon ?? 'Edit'}</ScreenReader>
            </Button>
          </div>
        )}
      </div>

      {subcontent && subcontent}
    </div>
  );

  return <Card />;
};
