import * as React from 'react';
import { HouseholdPerson, Registration } from 'core/api';

// Local Imports
import './CartDetails.scss';
import { getRegFeeLineItem, totalLineItems } from '../../../core/utilities';

interface CartDetailsProps {
  isSelected: boolean;
  member: HouseholdPerson;
  registrations: Registration[];
}

export const CartDetails = ({ isSelected, member, registrations }: CartDetailsProps) => {
  const memberRegistrations = registrations.filter((r) => r.householdPersonId === member.id);

  return (
    <>
      {memberRegistrations.length > 0 && (
        <div className="CartDetails sui-mt-3">
          <h3 className="title sui-font-normal sui-body sui-w-full sui-text-center sui-text-gray-8">
            <span className={`${isSelected ? 'u-bgPrimary1' : 'u-bgForeground'}`}>In Cart</span>
          </h3>
          <div className="sui-mt-2">
            {memberRegistrations.map((registration) => (
              <div key={`${member.id}_${registration.id}`} className="sui-flex sui-justify-between">
                <h3 className="sui-body sui-font-normal sui-my-0.5 sui-text-gray-8">
                  {getRegFeeLineItem(registration)?.name}
                </h3>
                <h3 className="sui-body sui-font-normal sui-my-0.5 sui-text-gray-8">{totalLineItems(registration)}</h3>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
