import * as React from 'react';
import { useFormikContext } from 'formik';
import { ValidationError } from 'yup';

// Local imports
import { FormAdjustment, FormField as FormFieldModel } from 'core/api';
import { isVisible, toShortDollar, Values } from 'core/utilities';
import { getValidation } from 'core/validations';
import { FieldType, FormField, FormFieldType } from '../FormField/FormField';
import { AdjustmentValueCollector } from '../AdjustmentValue/AdjustmentValueCollector';

interface Props {
  field: FormFieldModel;
}

export const Question = ({ field, ...props }: Props) => {
  const values = useFormikContext().values as Values;

  if (!isVisible(field.visibilityRules, values as Values)) {
    return null;
  }

  const value = values[field.id];

  const createCollector = (v: string | string[], optionValue?: string) => (
    <AdjustmentValueCollector
      fieldValue={Array.isArray(value) ? value?.find((v: unknown) => v === optionValue) || '' : (v as string)}
      adjustments={(field.adjustments as FormAdjustment[]) || []}
    />
  );

  return (
    <FormField
      disabled={isFieldDisabled(field)}
      placeholder={getFieldPlaceholder(field)}
      type={getFieldType(field)}
      label={field.label || field.formFieldDefinition.label}
      name={String(field.id)}
      multiselect={field.multiselect}
      adjustmentAddon={createCollector}
      required={field.required}
      options={field.options?.map((option: string) =>
        createQuestionOption(option, field.adjustments as FormAdjustment[])
      )}
      validate={async (v) => {
        try {
          const validationVal = Array.isArray(v) && v.length === 0 ? null : v;

          await getValidation(field).validate(validationVal);
        } catch (e: unknown) {
          return (e as ValidationError).errors.join(' ');
        }
      }}
      {...props}
    />
  );
};

const isFieldDisabled = (field: FormFieldModel) => {
  return (
    (field.formFieldDefinition.name === FieldType.GENDER ||
      field.formFieldDefinition.name === FieldType.BIRTHDATE ||
      field.formFieldDefinition.name === FieldType.FIRST_NAME ||
      field.formFieldDefinition.name === FieldType.LAST_NAME) &&
    field.target === 'participant'
  );
};

function getFieldPlaceholder(field: FormFieldModel) {
  if (field.formFieldDefinition.name === FieldType.EMAIL) {
    return 'email@email.com';
  }

  return field.label;
}

function getFieldType(field: FormFieldModel) {
  if (field.type === 'date') {
    return FormFieldType.DATETIME;
  }

  if (field.internal) {
    return FormFieldType.HIDDEN;
  }

  return field.type as FormFieldType;
}

function createQuestionOption(option: string, adjustments: FormAdjustment[]) {
  const adjustment = adjustments.find((a) => a.answerToMatch === option);

  let label = option;

  if (adjustment && adjustment.amount !== null) {
    label += ` - ${adjustment.definition.name} (${Math.sign(adjustment.amount) === -1 ? '-' : ''}$${toShortDollar(
      Math.abs(adjustment.amount)
    )}`;
    if (adjustment.maxAmount) {
      label += ` to $${toShortDollar(adjustment.maxAmount)}`;
    }
    label += ')';
  }

  return { value: option, label };
}
