import './AlphaNumericInput.scss';

interface Props {
  hasError?: boolean;
  mods?: string;
  name: string;
  onChange: (value: string) => void;
  placeholderText: string;
  testId?: string;
  value: string;
}

export const AlphaNumericInput = ({
  hasError = false,
  mods = '',
  name,
  onChange,
  placeholderText,
  testId = 'discount-entry-input',
  value
}: Props) => {
  const classes = ['AlhaNumericInput', 'Input', hasError ? 'sui-border-red-4' : '', mods].join(' ').trim();

  return (
    <input
      data-testid={testId}
      id={name}
      className={`${classes} sui-h-6 sui-px-1 sui-py-1 sui-body sui-border-gray-80`}
      placeholder={placeholderText}
      onChange={(e) => onChange(e.target.value)}
      value={value}
    />
  );
};
