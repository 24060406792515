/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthResult } from '../models/AuthResult';
import type { CreateUser } from '../models/CreateUser';
import type { CreateUserResult } from '../models/CreateUserResult';
import type { LoginData } from '../models/LoginData';
import type { ResetPassword } from '../models/ResetPassword';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { User } from '../models/User';
import type { UserExists } from '../models/UserExists';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Returns true on success - sends reset to given email address
     * @param requestBody
     * @returns ResetPassword Success
     * @throws ApiError
     */
    public static resetPassword(
        requestBody?: ResetPasswordRequest,
    ): CancelablePromise<ResetPassword> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/reset',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Loads the current user
     * @returns User Success
     * @throws ApiError
     */
    public static getUser(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/me',
        });
    }

    /**
     * Logout a user
     * @returns any Success
     * @throws ApiError
     */
    public static logout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/logout',
        });
    }

    /**
     * Login a user
     * @param requestBody
     * @returns AuthResult Success
     * @throws ApiError
     */
    public static login(
        requestBody?: LoginData,
    ): CancelablePromise<AuthResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns true if user exists for email address
     * @param email
     * @returns UserExists Success
     * @throws ApiError
     */
    public static userExists(
        email: string,
    ): CancelablePromise<UserExists> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/exists',
            query: {
                'email': email,
            },
        });
    }

    /**
     * Creates a new user
     * @param requestBody
     * @returns CreateUserResult Success
     * @throws ApiError
     */
    public static createUser(
        requestBody?: CreateUser,
    ): CancelablePromise<CreateUserResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
